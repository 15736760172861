.no--margin {
	@include margin(0 !important);
}

.no--padding {
	@include padding(0 !important);
}

.no--border {
	border: 0;
}

.no--border-radius {
	@include borderRadius(0 !important);
}

.m--0-auto {
	@include margin(0 auto !important);
}

.width--auto {
	@include width(auto !important);
}

.width--full {
	@include width(100% !important);
}

.width--half {
	@include width(50% !important);
}

.width--10-per {
	@include width(10% !important);
}

.width--15-per {
	@include width(15% !important);
}

.width--19-per {
	@include width(19% !important);
}

.width--20-per {
	@include width(20% !important);
}

.width--30-per {
	@include width(30% !important);
}

.width--32-per {
	@include width(32% !important);
}

.width--40-per {
	@include width(40% !important);
}

.width--70-per {
	@include width(70% !important);
}

.width--80-per {
	@include width(80% !important);
}

.width--90-per {
	@include width(90% !important);
}

.width--25-per {
	@include width(25% !important);
}

.width--20-px {
	@include width(20px !important);
}
.width--30-px {
	@include width(30px !important);
}

.width--20-px {
	@include width(20px !important);
}

.width--35-px {
	@include width(35px !important);
}

.width--40-px {
	@include width(40px !important);
}

.width--50-px {
	@include width(50px !important);
}

.width--75-px {
	@include width(75px !important);
}

.width--100-px {
	@include width(100px !important);
}

.width--300-px {
	@include width(300px !important);
}

.width--500-px {
	@include width(500px !important);
}

.width--fit-content {
	@include width(fit-content !important);
}

.max-width--350 {
	@include max-width(350px !important);
	@include width(100% !important);
}
.max-width--160 {
	@include max-width(160px !important);
}
.max-width--90 {
	@include max-width(90px !important);
}

.height--full {
	@include height(100% !important);
}
.height--100vh {
	@include height(100vh !important);
}
.height--20-px {
	@include height(20px !important);
}

.height--30-px {
	@include height(30px !important);
}

.height--35-px {
	@include height(35px !important);
}

.height--40-px {
	@include height(40px !important);
}

.height--50-px {
	@include height(50px !important);
}
.height--100-px {
	@include height(100px !important);
}
.height--200-px {
	@include height(200px !important);
}
.height--300-px {
	@include height(300px !important);
}

.min-height--inherit {
	@include min-height(inherit);
}

.box-size--border-box {
	@extend %box-sizing--border-box;
}

.d-none {
	@extend %d--none;
}

.d-block {
	@extend %d--block;
}

.padding--2--3 {
	@include padding(0.25rem 0.3rem);
}

.padding--0--15 {
	@include padding(0px 15px);
}

.p--5 {
	@include padding(5px);
}

.p--10 {
	@include padding(10px);
}

.p--25 {
	@include padding(25px);
}

.pb--0 {
	@include paddingBottom(0px !important);
}

.pb--5 {
	@include paddingBottom(5px !important);
}

.pb--10 {
	@include paddingBottom(10px !important);
}

.pb--20 {
	@include paddingBottom(20px);
}

.pl--0 {
	@include paddingLeft(0 !important);
}

.pl--5 {
	@include paddingLeft(5px !important);
}

.pl--10 {
	@include paddingLeft(10px !important);
}

.pl--15 {
	@include paddingLeft(15px !important);
}

.pl--20 {
	@include paddingLeft(20px);
}

.pl--25 {
	@include paddingLeft(25px);
}

.pr--0 {
	@include paddingRight(0 !important);
}

.pr--5 {
	@include paddingRight(5px);
}
.pr--10 {
	@include paddingRight(10px);
}

.pr--20 {
	@include paddingRight(20px);
}

.pr--25 {
	@include paddingRight(25px);
}

.pl--15 {
	@include paddingLeft(15px);
}

.pl--30 {
	@include paddingLeft(30px);
}

.pr--15 {
	@include paddingRight(15px);
}

.pr--10 {
	@include paddingRight(10px);
}

.pr--30 {
	@include paddingRight(30px);
}

.pt--0 {
	@include paddingTop(0px !important);
}
.pt--3 {
	@include paddingTop(3px !important);
}
.pt--12 {
	@include paddingTop(12px !important);
}
.pt--15 {
	@include paddingTop(15px !important);
}

.pt--20 {
	@include paddingTop(20px);
}

.pb--15 {
	@include paddingBottom(15px !important);
}

.pb--20 {
	@include paddingBottom(20px);
}

.pb--25 {
	@include paddingBottom(25px);
}

.pt--30 {
	@include paddingTop(30px);
}

.pb--30 {
	@include paddingBottom(30px);
}

.pb--80 {
	@include paddingBottom(80px);
}
.mt--0 {
	@include marginTop(0 !important);
}

.mt--3 {
	@include marginTop(3px !important);
}

.mt--5 {
	@include marginTop(5px !important);
}

.mt--10 {
	@include marginTop(10px);
}

.mt--15 {
	@include marginTop(15px !important);
}

.mt--20 {
	@include marginTop(20px !important);
}

.mt--25 {
	@include marginTop(25px !important);
}

.mt--28 {
	@include marginTop(28px !important);
}

.mt--30 {
	@include marginTop(30px !important);
}

.mt--35 {
	@include marginTop(35px !important);
}

.mt--40 {
	@include marginTop(40px !important);
}

.mt--50 {
	@include marginTop(50px !important);
}

.mt--60 {
	@include marginTop(60px !important);
}

.mt--80 {
	@include marginTop(80px !important);
}

.mt--100 {
	@include marginTop(100px !important);
}
.mt--80 {
	@include marginTop(80px !important);
}

.mt--150 {
	@include marginTop(150px !important);
}

.mt--180 {
	@include marginTop(180px !important);
}
.mt--200 {
	@include marginTop(200px !important);
}

.ml--5 {
	@include marginLeft(5px);
}

.ml--10 {
	@include marginLeft(10px);
}

.ml--15 {
	@include marginLeft(15px);
}

.ml--20 {
	@include marginLeft(20px);
}

.ml--25 {
	@include marginLeft(25px);
}

.ml--30 {
	@include marginLeft(30px);
}

.ml--35 {
	@include marginLeft(35px);
}
.ml--250 {
	@include marginLeft(250px);
}
.mr--0 {
	@include marginRight(0 !important);
}

.mr--5 {
	@include marginRight(5px);
}

.mr--15 {
	@include marginRight(15px !important);
}

.mr--35 {
	@include marginRight(35px);
}

.mr--10 {
	@include marginRight(10px);
}

.mr--20 {
	@include marginRight(20px !important);
}

.mr--30 {
	@include marginRight(30px);
}

.mr--40 {
	@include marginRight(40px);
}

.mr--50 {
	@include marginRight(50px);
}

.mr--60 {
	@include marginRight(60px);
}

.mb--0 {
	@include marginBottom(0 !important);
}

.mb--5 {
	@include marginBottom(5px);
}

.mb--10 {
	@include marginBottom(10px !important);
}

.mb--15 {
	@include marginBottom(15px !important);
}

.mb--20 {
	@include marginBottom(20px !important);
}

.mb--25 {
	@include marginBottom(25px !important);
}

.mb--30 {
	@include marginBottom(30px !important);
}

.mb--40 {
	@include marginBottom(40px !important);
}

.mb--50 {
	@include marginBottom(50px !important);
}

.mb--100 {
	@include marginBottom(100px !important);
}

.d--inline-block {
	@extend %d--inline-block;
}

.flex {
	@extend %flex;
}

.flex--column {
	@extend %flex--column;
}

.flex--column-reverse {
	@extend %flex--column-reverse;
}

.flex--row {
	@extend %flex--row;
}

.flex--row-reverse {
	@extend %flex--row-reverse;
}

.flex--wrap {
	@extend %flex--wrap;
}

.justify__content--center {
	@extend %justify__content--center;
}

.justify__content--end {
	@extend %justify__content--end;
}

.justify__content--start {
	@extend %justify__content--start;
}

.justify__content--between {
	@extend %justify__content--between;
}

.justify__content--around {
	@extend %justify__content--around;
}

.justify__content--evenly {
	@extend %justify__content--evenly;
}

.align__items--center {
	@extend %align__items--center;
}

.align__items--end {
	@extend %align__items--end;
}

.align__items--start {
	@extend %align__items--start;
}

.align__items--baseline {
	@extend %align__items--baseline;
}

.align__content--baseline {
	align-content: baseline;
}

.object__fit--cover {
	@extend %object__fit--cover;
}

.object__fit--contain {
	@extend %object__fit--contain;
}

.text--left {
	@include textAlign(left !important);
}

.text--right {
	@include textAlign(right !important);
}

.text--center {
	@include textAlign(center !important);
}

.line-height--default {
	@include lineHeight(1);
}

.line-height--13px {
	@include lineHeight(13px !important);
}

.line-height--15px {
	@include lineHeight(15px !important);
}

.line-height--17px {
	@include lineHeight(17px !important);
}

.line-height--18px {
	@include lineHeight(18px !important);
}
.line-height--19px {
	@include lineHeight(19px !important);
}

.line-height--20px {
	@include lineHeight(20px !important);
}

.line-height--21px {
	@include lineHeight(21px !important);
}

.line-height--22px {
	@include lineHeight(22px !important);
}

.line-height--24px {
	@include lineHeight(24px !important);
}

.line-height--25px {
	@include lineHeight(25px !important);
}

.line-height--26px {
	@include lineHeight(26px !important);
}

.line-height--27px {
	@include lineHeight(27px !important);
}

.line-height--28px {
	@include lineHeight(28px !important);
}

.line-height--30px {
	@include lineHeight(30px !important);
}

.line-height--33px {
	@include lineHeight(33px !important);
}

.line-height--40px {
	@include lineHeight(40px !important);
}

.line-height--45px {
	@include lineHeight(45px !important);
}

.line-height--50px {
	@include lineHeight(50px !important);
}
.line-height--60px {
	@include lineHeight(60px !important);
}

.font-size--9 {
	font-size: 0.5625rem;
}

.font-size--10 {
	font-size: 0.625rem !important;
}

.font-size--11 {
	font-size: 0.6875rem !important;
}

.font-size--13 {
	font-size: $font-size-xs;
}

.font-size--14px {
	font-size: $font-size-sm;
}

.font-size--16px {
	font-size: $font-size-browser-default;
}

.font-size--xs {
	font-size: $font-size-xs;
}

.font-size--sm {
	font-size: $font-size-sm !important;
}

.font-size--17 {
	font-size: $font-size-md;
}

.font-size--19 {
	font-size: 1.1875rem;
}

.font-size--20 {
	font-size: 1.25rem;
}

.font-size--23 {
	font-size: 1.4375rem;
}
.font-size--24 {
	font-size: 1.55rem;
}
.font-size--28 {
	font-size: 1.75rem;
}

.font-size--30 {
	font-size: 1.875rem;
}

.font-size--42 {
	font-size: 2.625rem;
}
.font-size--48 {
	font-size: 3rem;
}
.text--uppercase {
	@include textTransform(uppercase);
}

.text--capitalize {
	@include textTransform(capitalize);
}
.text--lowercase {
	@include textTransform(lowercase);
}

.text-decoration--underline {
	@include textDecoration(underline);
}

.text-decoration--none {
	@include textDecoration(none);
}

.list-type--none {
	list-style-type: none;
}
.b-radius--10 {
	@include borderRadius(10px !important);
}

.b-radius--15 {
	@include borderRadius(15px !important);
}

.b-radius--20 {
	@include borderRadius(20px !important);
}
.b-radius--25 {
	@include borderRadius(25px !important);
}
.b-radius--30 {
	@include borderRadius(30px !important);
}
.b-radius--40 {
	@include borderRadius(40px !important);
}
.b-radius--50 {
	@include borderRadius(50px !important);
}

.position--absolute {
	@include position(absolute !important);
}
.position--sticky {
	@include position(sticky !important);
}

.position--relative {
	@include position(relative !important);
}
.position--fixed {
	@include position(fixed !important);
}
.z-index--1 {
	@include zIndex(1 !important);
}

.z-index--3 {
	@include zIndex(3 !important);
}

.overflow--hidden {
	@include overflow(hidden !important);
}

.overflow--auto {
	@include overflow(auto !important);
}

.d--inline-flex {
	@extend %d--inline-flex;
}

.no--margin {
	@include margin(0 !important);
}

.font-weight--900 {
	font-weight: 900;
}

.font-weight--700 {
	font-weight: $font-bold;
}

.font-weight--500 {
	font-weight: $font-medium;
}
.font-weight--600 {
	font-weight: $font-large;
}

.font-weight--400 {
	font-weight: $font-regular;
}

.text-align--center {
	@include textAlign(center);
}

.link-text--blue {
	color: $link-text--blue;
}

.text--primary {
	color: $bright-orange;
}

.text--green {
	color: $light-green;
}

.text--grey {
	color: $grey-color;
}

.text--white {
	color: $white-color;
}

.text--black {
	color: $black-color;
}

.text--blue {
	color: blue;
}

.cursor--pointer {
	cursor: pointer;
}

.pointer--none {
	pointer-events: none !important;
}

.error {
	color: red;
	margin-bottom: 0;
	font-size: 14px;
	margin-top: 10px;
	font-weight: 600;

	&::first-letter {
		text-transform: uppercase;
	}
}
