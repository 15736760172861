@font-face {
	font-family: 'montserrat-regular';
	src: url('../fonts/Montserrat-Regular.ttf');
}
@font-face {
	font-family: 'montserrat-medium';
	src: url('../fonts/Montserrat-Medium.ttf');
}
@font-face {
	font-family: 'montserrat-semibold';
	src: url('../fonts/Montserrat-SemiBold.ttf');
}
@font-face {
	font-family: 'montserrat-bold';
	src: url('../fonts/Montserrat-Bold.ttf');
}
