@media screen and (max-width: 1247px) {
	.header {
		ul {
			li {
				margin-right: 0px;
				span {
					font-size: 14px !important;
				}
			}
		}
	}
	.admission--btn {
		font-size: 14px !important;
	}
}

@media screen and (max-width: 1110px) {
	.header {
		ul {
			li {
				span {
					font-size: 10px !important;
				}
			}
		}
	}
	.admission--btn {
		font-size: 10px !important;
		padding: 10px 30px !important;
	}
}

@media screen and (max-width: 1100px) {
	.homepage--container {
		.tag-line--text {
			font-size: 30px;
			width: 380px !important;
		}
	}

	.flip-number {
		.flip-number--wrapper {
			margin-right: 50px;
			margin-left: 0px;
		}
	}

	.contact-us--container {
		.working-hours--wrapper {
			.working-hours--content {
				.working-hours--time {
					margin-left: 10px;
				}
			}
		}
	}
}

@media screen and (max-width: 1000px) {
	.homepage--container {
		.tag-line--text {
			font-size: 20px;
			width: 300px !important;
			line-height: 45px !important;
		}
		.btn--wrapper {
			padding-top: 46px;
		}
		.hero-image--wrapper {
			width: 400px !important;
		}
	}

	.infra--container {
		.infra--img {
			margin-right: 50px !important;
		}
	}

	.flip-number {
		flex-wrap: wrap;
		box-sizing: border-box;

		.flip-number--wrapper {
			flex: 50%;
			margin: 30px 0px 0px 0px;
		}
	}

	.contact-us--container {
		.contact-tagline {
			line-height: 20px !important;
			padding: unset;
			span {
				top: 50;
			}
		}
		.contact--btn {
			margin-top: 35px !important;
		}
		.contact-details--wrapper {
			flex-wrap: wrap;
			.map--box {
				width: 100% !important;
				flex: 100%;
			}
			.contact-email--wrapper {
				margin-left: unset;
				flex: 0 1 calc(50% - 10px);
			}
			.working-hours--wrapper {
				flex: 0 1 calc(50% - 10px);
				.working-hours--content {
					.working-hours--time {
						margin-left: 10px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 950px) {
	.navbar-menu {
		display: none !important;
	}
	.mini-menu--container {
		display: block !important;
	}
}

@media screen and (max-width: 900px) {
	.chair-man--container {
		.chair-man--details {
			flex-direction: column;
			align-items: center;
			.chair-man--text {
				width: 100% !important;
			}
		}
	}

	.footer--container {
		flex-wrap: wrap;
		.footer--box {
			flex: 30% 1;
		}
	}
}

@media screen and (max-width: 880px) {
	.homepage--container {
		flex-direction: column-reverse;
		align-items: center;
		.tag-line--text {
			font-size: 20px;
			width: 500px !important;
			line-height: 45px !important;
		}
		.hero-image--wrapper {
			margin: unset !important;
		}
	}
	.infra--container {
		.infra--img {
			border-radius: 0px;
			height: 300px;
		}
	}

	.contact-us--container {
		.contact-details--wrapper {
			.contact-email--wrapper {
				margin-left: unset;
				flex: 100%;
			}
			.working-hours--wrapper {
				flex: 100%;
				margin-top: 30px;
				.working-hours--content {
					.working-hours--time {
						margin-left: 10px;
					}
				}
			}
		}
	}

	.maintenance-text {
		font-size: 30px;
	}

	.election--img {
		width: 30% !important;
	}
}

@media screen and (max-width: 650px) {
	.react-pdf__Page__canvas {
		width: 100% !important;
		height: 100% !important;
	}
}

@media screen and (max-width: 600px) {
	.homepage--container {
		flex-direction: column-reverse;
		align-items: center;
		.tag-line--text {
			font-size: 15px;
			width: 350px !important;
			line-height: 35px !important;
		}
		.hero-image--wrapper {
			width: 350px !important;
		}
	}

	.infra--container {
		.infra--text {
			font-size: 35px !important;
			width: 350px !important;
			line-height: 35px !important;
			span {
				top: 45px;
				line-height: 25px !important;
			}
		}
		.infra--img {
			height: 230px !important;
		}
		.secondary--btn {
			margin-top: 50px !important;
		}
	}

	.facility--text {
		font-size: 35px !important;
		line-height: 35px !important;
		span {
			line-height: 25px !important;
		}
	}

	.facility--container {
		.secondary--btn {
			margin-top: 50px !important;
		}
		.slider--container {
			.slider--wrapper {
				width: 500px !important;
				.image-wrapper {
					margin: 8px auto !important;
				}
			}
		}
	}

	.chair-man--container {
		.chair-man--details {
			.chair-man--img {
				width: 350px !important;
				height: 300px !important;
			}
		}
	}

	.table-head {
		font-size: 14px;
		padding: 5px;
	}

	.table-cell {
		font-size: 14px;
		padding: 5px;
	}

	.contact-us--container {
		.contact-tagline {
			font-size: 35px !important;
		}
	}

	.training-image--wrapper {
		width: 350px !important;
	}

	.maintenance-text {
		font-size: 25px;
	}
}

@media screen and (max-width: 500px) {
	.container {
		padding: 0 20px;

		.mini-navbar--box {
			right: -24px;
		}
	}

	.footer-wrapper {
		margin-top: 50px !important;
		.footer-link-text {
			font-size: 14px;
			font-weight: 400;
		}

		.footer-bottom-text-wrapper {
			flex-direction: column;
			text-align: center;
			padding: 10px;
			margin-top: 30px !important;
			.bottom-text {
				font-size: 12px;
				font-weight: 400;
				margin: 0 0 5px 0;
			}
		}
		.location-icon {
			width: 20px !important;
		}
	}

	.about--wrapper {
		.logo--wrapper {
			width: 260px !important;
		}
		.choose-school-content {
			p:nth-child(1) {
				font-size: 30px !important;
			}
		}
		.school-points--wrapper {
			flex-direction: column !important;
			div {
				width: 100% !important;
			}
		}
	}

	.facility--container {
		margin: 30px 0px !important;
		.facility-content {
			margin-bottom: 25px;
		}
		.slider--container {
			.slider--wrapper {
				width: 400px !important;
				.image-wrapper {
					margin: 8px auto !important;
				}
			}
		}
	}

	.chair-man--container {
		.chair-man--name {
			font-size: 25px !important;
			span {
				top: 80px;
				line-height: 20px !important;
			}
		}
		.chair-man--details {
			.chair-man--img {
				width: 250px !important;
				height: 300px !important;
			}
		}
	}

	.contact-us--container {
		margin-top: 50px !important;
		.contact-us--content {
			p:nth-child(2) {
				font-size: 20px;
			}
			p:nth-child(3) {
				line-height: 20px !important;
			}
		}
		.contact-tagline {
			font-size: 25px !important;
		}
		.contact--btn {
			margin-top: 52px !important;
		}
		.contact-title {
			font-size: 17px !important;
		}
		.contact-info-text {
			font-size: 14px !important;
		}

		.social-media-wrapper {
			margin-top: 30px !important;
			svg {
				width: 30px !important;
			}
		}

		.contact-email--wrapper {
			margin-top: 20px;
			p:nth-child(3) {
				margin-top: 40px !important;
			}
		}
	}

	.footer--container {
		justify-content: space-between;
		.footer--box {
			flex: 100%;
		}
	}

	.maintenance-text {
		font-size: 18px;
	}

	.photos-title {
		font-size: 25px;
	}

	.election--img {
		width: 29% !important;
	}

	.activity-info {
		line-height: 30px !important;
	}

	.secondary-title {
		margin: 10px 0;
	}

	.table-cell {
		font-size: 12px;
	}
}

@media screen and (max-width: 450px) {
	.homepage--container {
		flex-direction: column-reverse;
		align-items: center;
		.tag-line--text {
			font-size: 15px;
			width: 250px !important;
			line-height: 35px !important;
			span {
				font-size: 10px;
			}
		}
		.btn--wrapper {
			flex-direction: column;
			padding-top: 50px;
			button {
				margin: 10px 0px;
			}
		}
		.hero-image--wrapper {
			width: 250px !important;
		}
	}

	.infra--container {
		.infra--text {
			font-size: 25px !important;
			width: 250px !important;
			line-height: 35px !important;
			span {
				top: 45px;
				font-size: 10px;
				line-height: 25px !important;
			}
		}
		.infra--img {
			height: 200px !important;
			width: 100% !important;
		}
	}

	.facility--text {
		font-size: 25px !important;
	}

	.title-info {
		font-size: 16px;
		line-height: 30px !important;
	}

	.facility--container {
		.slider--container {
			.slider--wrapper {
				width: 259px !important;
				.image-wrapper {
					margin: 8px 0px !important;
					width: 200px;
					height: 200px;
					.infra--img {
						width: 200px;
						height: 150px;
					}
					p {
						font-size: 16px;
					}
					.facility--sub-text {
						width: 250px !important;
						font-size: 10px;
					}
				}
			}
		}
	}

	.chair-man--container {
		.chair-man--name {
			font-size: 15px !important;
			width: 200px !important;
			span {
				top: 80px;
				line-height: 20px !important;
				font-size: 10px !important;
			}
		}
		.chair-man--details {
			.chair-man--text {
				p {
					font-size: 10px !important;
					line-height: 25px !important;
				}
			}
		}
	}

	.contact-us--container {
		.contact-tagline {
			padding: 20px 0px 0px 0px;
			font-size: 15px !important;
		}
		.contact--btn {
			margin-top: 52px !important;
		}
	}

	.copy-right--container {
		flex-wrap: wrap;
	}

	.training-title {
		font-size: 16px !important;
		line-height: 25px !important;
		margin-bottom: 20px !important;
	}

	.training-image--wrapper {
		width: 250px !important;
	}

	.footer-wrapper .location-icon {
		width: 25px !important;
	}

	.working-hours--wrapper {
		tr {
			td {
				padding: 5px;
				font-size: 12px;
			}
		}
	}

	.table-head {
		font-size: 12px;
	}

	.table-cell {
		font-size: 8px;
	}

	.video-gallery {
		.video-container {
			video {
				width: calc(100% - 20px) !important;
				height: calc(100% - 20px) !important;
			}
		}
	}
}

@media screen and (max-width: 345px) {
	.homepage--container {
		flex-direction: column-reverse;
		align-items: center;
		.tag-line--text {
			font-size: 10px;
			width: 200px !important;
			line-height: 35px !important;
			span {
				font-size: 10px;
			}
		}
		.btn--wrapper {
			flex-direction: column;
			padding-top: 50px;
			button {
				margin: 10px 0px;
				padding: 10px 0px;
			}
		}
		.hero-image--wrapper {
			width: 200px !important;
		}
	}

	.infra--container {
		.infra--text {
			font-size: 20px !important;
			width: 220px !important;
			line-height: 35px !important;
			span {
				top: 45px;
				font-size: 10px;
				line-height: 25px !important;
			}
		}
		.infra--img {
			height: 150px !important;
		}
	}

	.chair-man--container {
		.chair-man-content {
			p {
				font-size: 20px !important;
				span {
					margin-top: 60px !important;
					line-height: 25px !important;
				}
			}
		}
	}

	.facility--container {
		.slider--container {
			.slider--wrapper {
				width: 200px !important;
				.image-wrapper {
					margin: 8px auto !important;
					width: 200px;
					height: 270px;
					.infra--img {
						width: 200px;
						height: 150px;
					}
					.facility--sub-text {
						width: 200px !important;
						font-size: 10px;
					}
				}
			}
		}
	}

	.working-hours--content {
		flex-wrap: wrap;
		p:nth-child(1) {
			flex: 100%;
		}
		.working-hours--time {
			flex: 1 1 calc(50% - 5px);
		}
		.working-hours--text {
			flex: 0 1 calc(50% - 10px);
			margin-left: unset !important;
		}
	}

	.activity-title {
		font-size: 18px;
	}

	.activity-info {
		font-size: 14px;
	}
}
