@import '../styles/variables';

.primary--btn {
	background-color: $bright-orange;
	padding: 10px 40px;
	color: $white-color;
	border: none;
}

.container {
	max-width: 1200px;
	padding: 0 40px;
	margin: 0 auto;
}

.secondary--btn {
	border: 1px solid $bright-orange;
	background-color: transparent;
	padding: 10px 40px;
	color: $bright-orange;
}

.top--0 {
	top: 0px;
}

.notification--popup {
	top: 60px;
	right: 91px;
	width: 347px;
	background-color: #2f2d4f;
	border: 5px solid orange;
	p {
		color: $white-color;
		line-height: 30px;
	}
}

.footer--container {
	.footer--box:nth-child(4) {
		width: 365px;
	}
	.copy-right--container {
		background-color: #fafafa;
	}
}

.header {
	ul {
		list-style-type: none;

		li {
			margin-right: 10px;

			&.has-dropdown {
				position: relative;

				&:hover {
					.dropdown {
						display: block;
					}
				}

				.dropdown {
					display: none;
					position: absolute;
					top: 100%;
					left: -15%;
					min-width: 215px;
					background-color: #fff;
					list-style-type: none;
					padding: 10px;
					box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
					z-index: 1;

					li {
						&:hover {
							background-color: antiquewhite;
						}
					}
				}
			}
		}
	}
}

//mini-navbar menu
.mini-menu--icon {
	.bar1,
	.bar2,
	.bar3 {
		width: 25px;
		height: 2px;
		background-color: $bright-orange;
		margin: 6px 0;
		transition: 0.4s;
	}
}
.mini-navbar--box {
	background-color: $bright-orange;
	right: -40px;
	top: 0;
	.close-bar--wrapper {
		float: right;
		padding: 20px;
		.close-bar1,
		.close-bar2 {
			width: 30px;
			height: 5px;
			background-color: $white-color;
			margin: 6px 0;
			transition: 0.4s;
		}
		.close-bar1 {
			transform: translate(0, 10px) rotate(-45deg);
		}
		.close-bar2 {
			transform: translate(0, 0px) rotate(45deg);
		}
	}
	.mini-menu-list--container {
		.dropdown {
			display: none;
		}
	}
}

.react-pdf__Page__canvas {
	margin: 0 auto;
}

.annotationLayer {
	width: auto !important;
}

.header-bg {
	background: white;
}

.header-logo {
	width: 90px;
}

.slick-slide > div {
	margin: 5px;
}
